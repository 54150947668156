<template>
  <div>
    <div class="header_home_page">
      <div class="desktop_header">
        Programming
        <span>
          Ecosystem <img alt="" src="../assets/images/homePage/header.svg" />
        </span>
        for the AI Era
      </div>
      <div class="mobile_header">
        <div>
          <p>Programming</p>
          <span>
            Ecosystem
            <img alt="" src="../assets/images/homePage/header.svg" />
          </span>
        </div>
        <span>for the AI Era</span>
      </div>
    </div>
    <div class="cards_main_block">
      <div class="cards_main_block_bg">
        <img alt="cards_main_block_bg" src="../assets/images/homePage/bgIcons.png" />
      </div>
      <div class="cards_block">
        <Card v-for="card in cardsInfo" :key="card.id" :cards="card" />
      </div>
    </div>
    <div id="compilers_block" class="compilers_block">
      <CompilersLeftBlock />
      <CompilersRightBlock @openCommentBox="openModal('commentBox')" />
    </div>

    <div v-if="showModal" class="modal modalOpen">
      <div class="modal-content">
        <!-- Modal content goes here -->
        <button class="close-btn" @click="closeModal">
          <img alt="" src="../assets/images/xModal.svg">
        </button>
        <div class="leave_comment">
          <CommentsBox v-if="showModal === 'commentBox'" />
        </div>
      </div>
    </div>
    <div class="integrate">
      <TitleText :titleText="integrateTitle" />
      <IntegrateItemBlock />
    </div>
    <div class="teach">
      <TitleText :titleText="teachTitle" />
      <TeachBlock />
    </div>
    <OrganisationBlock />
    <DownloadBlock />
  </div>
</template>

<script>
import CommentsBox from '../components/Utils/CommentsBox'
import Card from '../components/HomePage/Card.vue'
import CompilersLeftBlock from '../components/HomePage/CompilersLeftBlock.vue'
import CompilersRightBlock from '../components/HomePage/CompilersRightBlock.vue'
import IntegrateItemBlock from '../components/HomePage/IntegrateItemBlock.vue'
import TitleText from '../components/TitleText.vue'
import TeachBlock from '../components/HomePage/TeachBlock.vue'
import OrganisationBlock from '../components/Utils/OrganisationBlock.vue'
import DownloadBlock from '../components/Utils/DownloadBlock.vue'

export default {
  name: 'home',
  components: {
    CommentsBox,
    Card,
    CompilersLeftBlock,
    CompilersRightBlock,
    IntegrateItemBlock,
    TitleText,
    TeachBlock,
    OrganisationBlock,
    DownloadBlock
  },
  data() {
    return {
      showModal: false,
      cardsInfo: [
        {
          id: 1,
          img: 'card1.svg',
          mainImg: 'woman.png',
          text:
            'Empower your coding journey with our zero-setup IDEs for 76+ languages',
          button: 'Online IDEs',
          title: 'Code',
          link: ''
        },
        {
          id: 2,
          img: 'card2.svg',
          mainImg: 'man.png',
          text:
            'Seamlessly connect IDEs to your applications, websites, and blogs with our powerful REST/ \n WebSocket APIs and Plugins',
          button: 'APIs & Plugin',
          title: 'Integrate',
          link: '/compiler-api'
        },
        {
          id: 3,
          img: 'card3.svg',
          mainImg: 'man_woman.png',
          text:
            'Transform your classroom with our innovative teaching and assessment solutions',
          button: 'Teach & Assess',
          title: 'Teach ',
          link: '/guru'
        },
        {
          id: 4,
          img: 'card4.png',
          mainImg: 'man_edu.png',
          text:
            'Integrate IDE into your LMS and platforms to enhance teaching and learning experiences',
          button: 'Explore',
          title: 'Academia',
          link: '/education-institutions'
        }
      ],
      integrateTitle: {
        title: 'Integrate',
        text:
          'Bloggers, EduTechs, Web Applications, Programming Articles,Software Library Providers, Assessment Providers'
      },
      teachTitle: {
        title: 'Teach and Assess',
        text:
          'Universities, Schools, Programming Bootcamps, Teachers, Recruiters'
      }
    }
  },
  methods: {
    openModal(value) {
      this.showModal = value
    },
    closeModal() {
      this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.header_home_page {
  padding: 60px 12% 60px 10%;
  background: #f8f9f8;

  .desktop_header {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #363739;

    span {
      display: flex;
      align-items: center;
      flex-direction: column;
      color: #fd5a0d;
      margin: 0px 22px;
    }
  }

  .mobile_header {
    display: none;
  }
}

.cards_main_block {
  position: relative;
  background: #f8f9f8;

  .cards_main_block_bg {
    width: 100%;
    position: absolute;

    img {
      width: 100%;
    }
  }

  .cards_block {
    display: flex;
    justify-content: space-between;
    padding: 30px 12% 90px 10%;
  }
}

.compilers_block {
  padding: 112px 12% 117px 10%;
  display: flex;
  align-items: start;
  background: #ffffff;
}

.integrate {
  padding: 8% 12% 8% 10%;
  background: #f8f9f8;
}

.teach {
  padding: 8% 12% 8% 10%;
}

@media only screen and (max-width: 1200px) {
  .header_home_page {
    .desktop_header {
      font-size: 42px;

      span {
        margin: 0px 15px;
      }
    }
  }
}

@media only screen and (max-width: 1040px) {
  .header_home_page {
    padding: 50px 8% 50px 6%;

    .desktop_header {
      font-size: 33px;
      line-height: 43px;

      span {
        margin: 0px 12px;
      }
    }
  }

  .cards_main_block {
    .cards_block {
      padding: 30px 8% 90px 6%;
    }
  }

  .compilers_block {
    padding: 100px 8% 100px 6%;
    gap: 20px;
  }

  .integrate {
    padding: 8% 12% 8% 6%;
  }

  .teach {
    padding: 8% 12% 8% 6%;
  }
}

@media only screen and (max-width: 769px) {
  .compilers_block {
    display: block;
  }

  .header_home_page {
    .desktop_header {
      font-size: 25px;
      line-height: 35px;

      span {
        margin: 0px 8px;

        img {
          width: 150px;
        }
      }
    }

  }

  .cards_block {
    flex-direction: column;
    gap: 63px;
  }
}

@media only screen and (max-width: 630px) {
  .header_home_page {
    text-align: center;
    padding: 12px 8% 0px 6%;

    img {
      width: unset;
    }

    .mobile_header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 140%;

      color: #363739;

      div {
        display: flex;
        align-items: baseline;
        justify-content: center;

        span {
          color: #fd5a0d;
          width: 44%;

          img {
            width: 80%;
          }
        }
      }
    }

    .desktop_header {
      display: none;
    }
  }

  .cards_main_block {
    .cards_main_block_bg {
      display: none;
    }

    .cards_block {
      flex-direction: column;
      gap: 63px;
    }
  }

  .compilers_block {
    padding: 36px 8% 85px 6%;
  }
}

@media only screen and (max-width: 450px) {
  .header_home_page {
    .mobile_header {
      div {
        span {
          margin-left: 10px;
          color: #fd5a0d;

          line-height: 100%;
          width: 40%;

          img {
            width: 95%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .header_home_page {
    padding: 13px 24px 0px 24px;

    .mobile_header {
      font-size: 26px;

      div {
        justify-content: space-between;
        flex-wrap: wrap;
        justify-content: center;

        span {
          color: #fd5a0d;
          width: 44%;
          line-height: 100%;
          margin-left: 5px;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .cards_main_block {
    .cards_block {
      padding: 38px 60px;
    }
  }

  .compilers_block {
    padding: 36px 22px 126px 22px;
  }

  .integrate {
    padding: 39px 24px;
  }

  .teach {
    padding: 34px 24px 28px 24px;
  }
}
</style>
