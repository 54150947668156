<template>
  <div class="integrate_item">
    <div>
      <img :src="`${require('../../assets/images/homePage/' + item.img)}`" />
    </div>

    <p>
      {{ item.text }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'integrate_item',
  props: {
    item: {
      type: Object,
      require: true
    }
  }
}
</script>
<style lang="scss" scoped>
.integrate_item {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 45%;
  > div {
    display: flex;
    width: 180px;
    img {
      width: 100%;
    }
  }
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 155.6%;
    margin-top: 56px;
  }
}
@media only screen and (max-width: 1040px) {
  .integrate_item {
    > div {
      width: 150px;
    }
    p {
      font-size: 15px;
      margin-top: 30px;
    }
  }
}
@media only screen and (max-width: 630px) {
  .integrate_item {
    width: 100%;
    margin-top: 60px;
    > div {
      width: 125px;
    }
    p {
      font-size: 16px;
      margin-top: 15px;
    }
  }
}
@media only screen and (max-width: 375px) {
  .integrate_item {
    > div {
      width: 90px;
    }
    p {
      font-family: "Inter";
      font-style: normal;
      font-size: 14px;
      line-height: 141%;
      width: 81%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
