<template>
  <div class="teach_block">
    <div class="woman_image">
      <img
        src="../../assets/images/homePage/teachWoman.png"
        alt="teach-woman"
      />
    </div>
    <div class="teach_img_text">
      <TeachItem v-for="item in teachItems" :item="item" :key="item.id" />
      <router-link to="/guru" class="teach_button">
        <MyButton>Courses & Assignments</MyButton>
      </router-link>
    </div>
  </div>
</template>
<script>
import TeachItem from './TeachItem.vue'
import MyButton from '../MyButton.vue'
export default {
  name: 'teach_block',
  data () {
    return {
      teachItems: [
        {
          id: 1,
          img: 'teach1.png',
          text: 'Start your own online Institute'
        },
        {
          id: 2,
          img: 'teach2.png',
          text: 'Courses and Assignments'
        },
        {
          id: 3,
          img: 'teach3.png',
          text: 'Private and Public'
        },
        {
          id: 4,
          img: 'teach4.png',
          text: 'Auto evaluation and scoring'
        },
        {
          id: 5,
          img: 'teach5.png',
          text: 'Online tests for interviews and recruitment'
        }
      ]
    }
  },
  components: {
    TeachItem,
    MyButton
  }
}
</script>
<style lang="scss" scoped>
.teach_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 58px;
  .teach_img_text {
    width: 45%;
    .teach_button {
      margin-top: 60px;
      display: flex;
    }
  }
  .woman_image {
    img {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 800px) {
  .teach_block {
    .woman_image {
      width: 60%;
    }
  }
}
@media only screen and (max-width: 630px) {
  .teach_block {
    display: block;
    .teach_img_text {
      width: 100%;
      .teach_button {
        margin-top: 40px;
        justify-content: center;
      }
    }
    .woman_image {
      display: flex;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 375px) {
  .teach_block {
    margin-top: 25px;
    .teach_img_text {
      text-align: start;
      .teach_button {
        margin-top: 27px;
        display: flex;
        justify-content: center;
      }
    }
    .woman_image {
      img {
        width: 100%;
      }
    }
  }
}
</style>
