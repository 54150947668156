<template>
  <div class="card">
    <div class="main_img">
      <img
        :src="`${require('../../assets/images/homePage/' + cards.mainImg)}`"
      />
    </div>
    <div class="card_img">
      <img :src="`${require('../../assets/images/homePage/' + cards.img)}`" />
      <p>{{ cards.title }}</p>
    </div>
    <div class="card_text">
      <p>{{ cards.text }}</p>
      <div @click="scrollToElement(cards.id)" class="my_button">
        <router-link :to="cards.link">
          <MyButton>{{ cards.button }}</MyButton></router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
import MyButton from '../MyButton.vue'
export default {
  name: 'card-home',
  props: {
    cards: {
      type: Object,
      required: true
    }
  },
  components: {
    MyButton
  },
  methods: {
    scrollToElement (id) {
      if (id === 1) {
        const y = compilers_block.getBoundingClientRect().top + window.scrollY
        window.scroll({
          top: y,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  width: 254px;
  display: flex;
  flex-direction: column;
  background: none;
  box-shadow: none;
  .card_img {
    margin-top: 61px;
    height: 66px;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      margin-top: -27px;
      margin-left: 55px;
    }
  }
  .card_text {
    margin-top: 20px;
    height: 162px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
    }
  }
  .main_img {
    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1040px) {
  .card {
    width: 219px;
    .card_text {
      p {
        font-size: 15px;
      }
    }
    .card_img {
      p {
        font-size: 24px;
        margin-top: -20px;
        margin-left: 40px;
      }
      img {
        width: 56px;
      }
    }
  }
}
@media only screen and (max-width: 769px) {
  .card {
    width: 100%;
    align-items: center;
    .card_text {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;

      text-align: center;
    }
    .main_img {
      img {
        width: 100%;
      }
    }
  }
}
</style>
