import { render, staticRenderFns } from "./TeachBlock.vue?vue&type=template&id=6ec0bc50&scoped=true&"
import script from "./TeachBlock.vue?vue&type=script&lang=js&"
export * from "./TeachBlock.vue?vue&type=script&lang=js&"
import style0 from "./TeachBlock.vue?vue&type=style&index=0&id=6ec0bc50&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ec0bc50",
  null
  
)

export default component.exports