<template>
  <div class="teach_item">
    <img :src="`${require('../../assets/images/homePage/' + item.img)}`" />
    <p>{{ item.text }}</p>
  </div>
</template>
<script>
export default {
  name: 'teach_item',
  props: {
    item: {
      type: Object,
      require: true
    }
  }
}
</script>
<style lang="scss" scoped>
.teach_item {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 22px;
  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 141%;
    letter-spacing: 0.01em;

    color: #000000;
  }
}
@media only screen and (max-width: 1040px) {
  .teach_item {
    gap: 22px;
    margin-top: 15px;
    p {
      font-size: 15px;
      line-height: 131%;
    }
  }
}
@media only screen and (max-width: 880px) {
  .teach_item {
    gap: 5px;
    img {
      width: 65px;
    }
  }
}
@media only screen and (max-width: 375px) {
  .teach_item {
    gap: 15px;
    margin-top: 14px;
    img {
      width: 44px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 141%;
    }
  }
}
</style>
