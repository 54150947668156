<template>
  <div class="integrate_items_block">
    <div class="integrate_items">
      <IntegrateItem v-for="item in items" :key="item.id" :item="item" />
    </div>
    <router-link to="/compiler-api">
      <MyButton class="integrate_button">APIs & Plugin</MyButton></router-link
    >
  </div>
</template>
<script>
import IntegrateItem from './IntegrateItem.vue'
import MyButton from '../MyButton.vue'
export default {
  name: 'integrate_items_block',
  data () {
    return {
      items: [
        {
          id: 1,
          img: 'integrate1.png',
          text: 'Integrate with standards-based REST and WebSocket APIs'
        },
        {
          id: 2,
          img: 'integrate2.png',
          text:
            'Using our plugins you can integrate an IDE with just three-lines of HTML Code – no API integration required.'
        }
      ]
    }
  },
  components: {
    IntegrateItem,
    MyButton
  }
}
</script>
<style lang="scss" scoped>
.integrate_items_block {
  padding: 56px 110px;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 56px;
  text-align: center;
  .integrate_items {
    display: flex;
    justify-content: space-between;
  }
  .integrate_button {
    margin-top: 87px;
  }
}
@media only screen and (max-width: 1040px) {
  .integrate_items_block {
    padding: 30px 80px;
    margin-top: 35px;

    .integrate_button {
      margin-top: 65px;
    }
  }
}
@media only screen and (max-width: 630px) {
  .integrate_items_block {
    margin-top: 0px;

    padding: 0px;
    background: unset;
    border-radius: unset;
    .integrate_items {
      display: block;
    }
    .integrate_button {
      margin-top: 65px;
    }
  }
}
@media only screen and (max-width: 375px) {
  .integrate_items_block {
    .integrate_button {
      margin-top: 50px;
    }
  }
}
</style>
